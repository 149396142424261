@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.progressTracker {
    margin-top: cssvar(spacing-6);
    margin-bottom: cssvar(spacing-4);
}

.formContainer {
    background-color: cssvar(bg-secondary-color);
}

.popupButton {
    margin-right: cssvar(spacing-2);
}

.cancelButton {
    margin-bottom: 250px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: cssvar(spacing-8);
    margin-bottom: cssvar(spacing-4);
}

.pressable {
    cursor: pointer;
}
