@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-themes';

.widgetContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}

.avatarLink {
    a {
        word-break: break-word;
    }
}
