@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.checkbox {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.advancedFilterAccordion {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.filtersButton {
    margin-top: cssvar(spacing-2);
}
