@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.firstRow {
    margin-top: cssvar(spacing-4);
    margin-bottom: cssvar(spacing-1);
}

.filtersButtonRow {
    margin-top: cssvar(spacing-1);
    margin-bottom: 0;

    &.filtersClosed {
        margin-bottom: cssvar(spacing-2);
    }

    &.clearFilters {
        display: flex;
        justify-content: flex-end;
    }
}

.dividerRow {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-3);
}

.firstFiltersRow {
    margin-bottom: cssvar(spacing-3);
}

.lastFiltersRow {
    margin-bottom: cssvar(spacing-4);
}

.filtersButton {
    padding-left: 0;
}
