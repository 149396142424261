@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.box {
    margin-top: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-3);
    background-color: cssvar(bg-scondary-color);
}

.row {
    margin-bottom: cssvar(spacing-1);
}

.summaryDivider {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.heading {
    padding-bottom: cssvar(spacing-3);
}

.dateRangeText {
    margin-bottom: 0;
}
