@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.backButton {
    margin-bottom: cssvar(spacing-2);
}

.createOrEditButton {
    margin-bottom: cssvar(spacing-2);
}

.cancelButton {
    margin-bottom: 175px;
}

.summaryCol {
    position: sticky;
    top: cssvar(spacing-5);
    flex: 1;
    z-index: 1;
}

.popupButton {
    margin-right: cssvar(spacing-2);
}
