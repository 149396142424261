@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.box {
    padding: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-2);
}

.quantityWidth input {
    width: 80px !important;
}

.infoText {
    padding-top: cssvar(spacing-6);
    color: cssvar(grayscale-90-color);
}

.tooltipText {
    margin: cssvar(spacing-half);
}

.errorText {
    color: cssvar(danger-dark-color);
    margin-bottom: 0;
}

.errorRow {
    margin-top: 0;
    margin-bottom: cssvar(spacing-2);
}
