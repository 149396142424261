@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.heading {
    display: inline-block;
    margin-right: cssvar(spacing-half);
    padding-bottom: cssvar(spacing-1);
}

.headingInfoButton {
    margin-bottom: cssvar(spacing-half);
    height: fit-content;
}

.tooltip {
    padding: cssvar(spacing-2);
}
