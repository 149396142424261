@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.rulesGridContainer {
    background-color: cssvar(bg-secondary-color);
    border-top: 1px solid cssvar(line-primary-color);
    padding-bottom: 250px;
}

.clearFiltersButton {
    margin-top: cssvar(spacing-4);
    width: 100%;
}

.noRulestText {
    margin-top: cssvar(spacing-4);
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: cssvar(spacing-8);
    margin-bottom: cssvar(spacing-4);
}
