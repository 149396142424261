@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.row {
    margin-bottom: cssvar(spacing-2);
}

.headerRow {
    margin-top: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-3);
}

.fullWidthSkeleton {
    width: 100%;
}

.ruleSkeleton {
    height: cssvar(spacing-12);
    width: 100%;
}
