@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.row {
    margin-bottom: cssvar(spacing-2);
}

.headerRow {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}
