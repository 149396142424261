@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.box {
    padding: cssvar(spacing-3);
    margin-right: cssvar(spacing-4);
    margin-bottom: cssvar(spacing-2);
}

.topRow {
    margin-bottom: cssvar(spacing-2);
}

.middleRow {
    margin-bottom: cssvar(spacing-1);
}

.lastRow {
    margin-bottom: cssvar(spacing-1);
}

.heading {
    padding: cssvar(spacing-1);
    padding-bottom: cssvar(spacing-1);
}
