@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.container {
    margin-right: cssvar(spacing-2);
}

.row {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.infoRow {
    display: flex;
    justify-content: flex-end;
    margin-top: cssvar(spacing-1);
    margin-bottom: 0;
    margin-right: cssvar(spacing-1);
}

.lastRow {
    margin-top: 0;
}

.errorRow {
    margin-top: cssvar(spacing-2);
    margin-bottom: 0;
}

.fieldset {
    margin-top: cssvar(spacing-2);
    margin-bottom: 10px;
}

.mainDivider {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-3);
}

.errorText {
    color: cssvar(danger-dark-color);
    margin-bottom: 0;
}
