@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.container {
    padding: cssvar(spacing-3);
    padding-bottom: 0;
    margin-bottom: 500px;
    margin-right: cssvar(spacing-4);
}

.row {
    margin-bottom: cssvar(spacing-2);

    &.firstRow {
        margin-top: cssvar(spacing-1);
    }
}

.spinnerRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headingRow {
    padding: 0;
    padding-left: cssvar(spacing-1);
    margin-bottom: cssvar(spacing-1);
}

.noOverlapsRow {
    padding-left: cssvar(spacing-1);
}
