@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.title {
    max-width: 515px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.short {
        max-width: 350px;
    }
}

.titleLink {
    display: flex;
    align-items: center;
    flex: 1;
    text-decoration: none;
    color: cssvar(primary-content-color);
    padding-right: cssvar(spacing-2);

    // &:hover .title {
    //   text-decoration: underline;
    // }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    font-weight: normal;
}

.statusIndicator {
    width: 9px;
    height: 9px;
    margin-right: cssvar(spacing-1);
    border-radius: 50%;
    background-color: cssvar(input-border-color);

    &.activeIndicator {
        background-color: cssvar(success-color);
    }
}

.tag {
    margin-right: cssvar(spacing-half);
}

.mainDivider {
    margin-bottom: cssvar(spacing-2);
}

.row {
    margin-bottom: cssvar(spacing-1);
}

.accordion {
    background-color: cssvar(bg-primary-color);
}

.summaryDivider {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.dateRangeText {
    margin-bottom: 0;
}
