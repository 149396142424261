@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.calendar {
    position: 'sticky';
    z-index: 10;
}

.dateRangeTag {
    margin-top: cssvar(spacing-1);
}
