@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.uploadBox {
    border-style: dashed;
    padding: cssvar(spacing-3);
    margin-bottom: 0;

    &.boxError {
        border-color: cssvar(danger-color);
    }

    &.boxAcceptedDrag {
        background-color: cssvar(highlight-color);
    }
}

.instructionList {
    margin-top: cssvar(spacing-2);
    margin-left: cssvar(spacing-2);
    margin-bottom: 0;
}

.dragAndDropText {
    color: cssvar(grayscale-90-color);
    display: inline-block;
    margin-right: cssvar(spacing-1);
    margin-bottom: 0;
}

.successText {
    color: cssvar(grayscale-90-color);
    display: flex;
    justify-content: flex-end;
    margin-top: cssvar(spacing-1);
}

.instructionText {
    margin-top: cssvar(spacing-half);
    margin-bottom: 0;
}

.uploadButton {
    margin-right: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-half);
    background-color: cssvar(bg-primary-color);
}

.fileTag {
    display: inline-block;
    margin-bottom: cssvar(spacing-half);
}

.errorText {
    color: cssvar(danger-dark-color);
    margin-bottom: 0;
    margin-top: cssvar(spacing-1);
}

.row {
    margin-top: cssvar(spacing-2);
    margin-bottom: 0;
}

.errorRow {
    margin-top: 0;
    margin-bottom: 0;
}
