@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-themes';

.container {
    padding-left: cssvar(spacing-4);
    padding-right: cssvar(spacing-6);
}

.buttonLeft {
    margin-right: cssvar(spacing-4);
    //margin-left: cssvar(spacing-4);
}

.hiddenButton {
    opacity: 0;
    pointer-events: none;
    margin-right: cssvar(spacing-4);
}

.buttonRight {
    //margin-right: cssvar(spacing-4);
    margin-left: cssvar(spacing-4);
}

.calendar {
    padding-left: cssvar(spacing-12);
}

.legendContaniner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    & > div {
        padding-left: cssvar(spacing-2);

        &:before {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-right: cssvar(spacing-1);
            margin-bottom: 1px;
            border-radius: 100%;
            background-color: cssvar(primary-brand-color);
        }
    }

    .increasedLegend {
        padding-left: 0;
        color: cssvar(primary-brand-color);

        &:before {
            background-color: cssvar(primary-brand-color);
        }
    }

    .decreasedLegend {
        color: cssvar(secondary-brand-color);

        &:before {
            background-color: cssvar(secondary-brand-color);
        }
    }

    .alphaLegend {
        color: cssvar(grayscale-90-color);

        &:before {
            background-color: cssvar(grayscale-90-color);
        }
    }
}

.tag {
    display: inline;
    margin-left: cssvar(spacing-1);
    padding-bottom: cssvar(spacing-half);
}
