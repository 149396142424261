@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-themes';

.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .buttonContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: cssvar(spacing-4) 0;
    }
}
