@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.box {
    padding: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-2);
}

.row {
    margin-bottom: cssvar(spacing-2);

    &.firstRow {
        margin-top: cssvar(spacing-1);
    }

    &.lastRow {
        margin-bottom: 0;
    }
}

.tooltipText {
    margin: cssvar(spacing-half);
}

.spinnerRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headingRow {
    padding: 0;
    padding-left: cssvar(spacing-1);
    margin-bottom: cssvar(spacing-1);
}
