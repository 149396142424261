@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.continueButton {
    margin-bottom: cssvar(spacing-2);
}

.box {
    padding: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-2);
}
