@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.container {
    margin-left: cssvar(spacing-1);
}

.row {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-1);
}

.lastRow {
    margin-top: cssvar(spacing-1);
}

.errorRow {
    margin-top: cssvar(spacing-2);
    margin-bottom: 0;
}

.errorText {
    color: cssvar(danger-dark-color);
    margin-bottom: 0;
}

.heading {
    padding-bottom: cssvar(spacing-7);
}
