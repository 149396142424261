@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.accordion {
    padding: cssvar(spacing-2);
    background-color: cssvar(bg-primary-color);
}

.summaryDivider {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.backButton {
    margin-bottom: cssvar(spacing-2);
}

.continueButton {
    margin-bottom: cssvar(spacing-2);
}
