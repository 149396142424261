@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.container {
    display: inherit;
    padding: 0;
    width: 100%;
}

.pagerCol {
    display: flex;
    justify-content: flex-start;
    margin-top: cssvar(spacing-1);
}

.textCol {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
}

.removeButton {
    margin-left: cssvar(spacing-2);
    display: block;
    margin-left: auto;
    margin-right: auto;
}
