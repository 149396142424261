@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.box {
    padding: cssvar(spacing-3);
    margin-bottom: cssvar(spacing-2);
}

.heading {
    display: inline-block;
    margin-right: cssvar(spacing-2);
    padding-bottom: cssvar(spacing-2);
}

.sliderRow {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
}

.headingIcon {
    margin-bottom: cssvar(spacing-half);
    height: fit-content;
}

.spinnerRow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin-top: cssvar(spacing-4);
    margin-bottom: cssvar(spacing-4);
}

.disabedSlider {
    margin-top: 26px;
}

.divider {
    margin-top: cssvar(spacing-4);
    margin-bottom: cssvar(spacing-2);
}
