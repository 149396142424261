@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

.tooltipBox {
    padding: cssvar(spacing-2);
    max-width: 375px;
}

.tooltipTitle {
    font-weight: bold;
}
