@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

:export {
    basecolor: cssvar(grayscale-50-color);
    increasecolor: cssvar(primary-brand-color);
    decreasecolor: cssvar(secondary-brand-color);
    alphacolor: cssvar(grayscale-90-color);
}

.chart {
    // Re-chart overrides
    text {
        font-size: cssvar(font-size-small);
        color: cssvar(primary-content-color);
        fill: cssvar(primary-content-color);
    }
}

.arrowButton {
    margin-top: cssvar(spacing-1);
}

.arrowButtonRightContainer {
    display: flex;
    justify-content: flex-end;
}
